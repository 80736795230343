import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlWebsite } from 'src/config';
import { useLanguage } from 'src/hooks/Language';
import { FaFacebook, FaInstagram, FaLinkedin, FaPager, FaPinterest, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { Container, Sponsor, Apoio, SponsorGrid, ApoioGrid, ApoioImg } from './styles';


interface ISponsor {
  url: string;
  link_url?: string;
  computer?: string;
  mobile?: string;

}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
interface ISponsors {
  title: string;
  title_font_color: string;
  title_font_size: string;
  position: number;
  sponsors: Array<ISponsor>;
  size?: string;
  status?: string;
}

interface IProps {
  data: Record<string, any>[];
  computer?: Record<string, any>;
  mobile?: Record<string, any>;
}

const SocialWidget: React.FC<IProps> = ({ data, computer, mobile }) => {
  const { language } = useLanguage();


  const list = {
    pinterest: (item) => <a title={item?.title || 'Pinterest'} href={item?.link} target="_BLANK"><FaPinterest style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,

    instagram: (item) => <a title={item?.title || 'Instagram'} href={item?.link} target="_BLANK"><FaInstagram style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,
    linkedin: (item) => <a title={item?.title || 'Linkedin'} href={item?.link} target="_BLANK"><FaLinkedin style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,
    facebook: (item) => <a title={item?.title || 'Facebook'} href={item?.link} target="_BLANK"><FaFacebook style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,
    youtube: (item) => <a title={item?.title || 'Youtube'} href={item?.link} target="_BLANK"><FaYoutube style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,
    website: (item) => <a title={item?.title || 'Website'} href={item?.link} target="_BLANK"><FaPager style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,
    whatsapp: (item) => <a title='Whatsapp' href={`https://api.whatsapp.com/send?l=pt&phone=${item?.link}&text=${encodeURI(
      item?.title || 'Olá, estou na sua página no Bariatric Channel'
    )}`} target="_BLANK"><FaWhatsapp style={{ color: computer?.color || '#333', fontSize: computer?.["font-size"] || '18px' }} /></a>,


  }


  return (
    <>


      {data?.length > 0 && data?.map(item => {

        return list?.[item?.type] ? list?.[item?.type](item) : <></>


      })}

    </>
  );
};

export default SocialWidget;
